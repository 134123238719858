<template>
  <div>
    <div class="container h-100">
        <Terms :show-modal="showModal" @hide="showModal = false" :isAcct="true" />
      <div class="row justify-content-center h-100 align-items-center my-5">
        <div class="col-xl-5 col-md-6">
          <div class="auth-form card">
            <div class="card-header">
              <h4 class="card-title">Make Deposit</h4>
            </div>


            <div class="card-body" v-if="approvedDocuments">
              <form action="verify-step-6.html" class="identity-upload" @submit.prevent="addAccount">
                <div class="form-row">
                  <div class="form-group row align-items-center col-12 mx-0">
                    <div class="col-sm-4 pl-0">
                        <label for="inputEmail3" class="col-form-label">
                        Select Account
                        </label>
                    </div>
                    <div class="col-sm-8 pr-0">
                        <div class="input-group mb-3 flex-wrap d-flex">
                        <select
                            name="wallet"
                            class="form-control col-12"
                            v-model="account"
                        >
                            <option value="" disabled selected>Select Account</option>
                            <option
                            v-for="account in accounts"
                            :key="account.id"
                            :value="account.account_number"
                            >
                            {{ account.description }}
                            </option>
                        </select>
                        </div>
                    </div>
                    </div>
                  <div v-if="account" class="form-group col-xl-12">
                    <label class="mr-sm-2">Account number</label>
                    <input
                      type="text"
                      class="form-control text-right"
                      readonly
                      placeholder=""
                      :value="account"
                    />
                  </div>
                  <div class="form-group col-12">
                    <label for="amount">Amount In USD</label>
                    <input
                        id="amount"
                        type="number"
                        name="usd_amount"
                        class="form-control text-right col-12"
                        placeholder=""
                        v-model.number="amount_fiat"
                    />
                    </div>
                    <div>
                        <label class="checkbox my-3">
                            <input
                            class="checkbox__check"
                            type="checkbox"
                            v-model="acceptTerms"
                            />
                            <span class="checkbox__checkmark"></span>
                            <span class="toggle-label text-capitalize">
                                I agree to
                                <a href="javascript:void(0)" @click="showModal = true;">Getcoins Terms and Conditions</a>
                            </span>
                        </label>
                    </div>

                  <div class="text-center col-12">
                    <router-link to="trade" class="btn btn-primary mx-2">
                      Back
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-success mx-2"
                      @click.prevent="makeDeposit"
                      :disabled="!amount_fiat || !account || !acceptTerms"
                    >
                      Deposit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div v-else>
                <br />&nbsp; &nbsp; &nbsp; &nbsp;
                <i class="fa fa-lock"></i> &nbsp; &nbsp; You need have an approved
                <router-link to="settings">VIP Application Form</router-link> to request a deposit.
                <br />
                <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Terms from '@/components/Terms.vue';
import api from '@/api';

export default {
    components: { Terms },
  data: () => ({
    approvedDocuments: false,
    pendingDocuments: false,
    hasError: false,
    showError: false,
    accounts: [],
    account: '',
    amount_fiat: null,
    showModal: false,
    acceptTerms: false,
  }),
  mounted() {
    this.getAccounts();

    api.user.getApprovedInfo().then(data => {
      if(data.length > 0){
        this.approvedDocuments = true;
        console.log("approved info is => ", data.length)
      }
    }, err => {
     this.$toast.error('Something went wrong, please refresh');
    })

    api.user.getPendingInfo().then(data => {

        console.log("pending info is => ", data.length)
      if (data.length > 0) {
        this.pendingDocuments = true;
      }
    }, err => {
     this.$toast.error('Something went wrong, please refresh');
    })
  },
  methods: {
     getAccounts() {
      api.account.read().then((res) => {
        this.accounts = res;
      });
    }, 
    makeDeposit() {
      this.$toast.clear();
      const data = {
        amount_fiat: this.amount_fiat,
        account_id: this.accounts.find((el) => el.account_number === this.account).id,
      };
      api.deposit.create(data).then((res) => {
        this.$toast.success('Transaction Successful');
        this.$router.push({ path: '/bank-details' });
      }).catch((err) => {
        this.$toast.error(err);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
